import request from '@/utils/request'
import qs from 'qs'

export function getCardOrderList(params) {
  return request({
    url: `/v1/card/orders?${qs.stringify(params)}&origin=2`,
    method: 'get'
  })
}

export function getCardOrderDetail(id) {
  return request({
    url: `/v1/card/orders/${id}?origin=2`,
    method: 'get'
  })
}

export function cancelOrder(id) {
  return request({
    url: `/v1/card/orders/${id}:cancel`,
    method: 'post'
  })
}

export function addOrder (data) {
  return request({
    url: '/v1/card/orders',
    method: 'post',
    data
  })
}