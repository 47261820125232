<!--
 * @Author: 月魂
 * @Date: 2021-03-24 16:04:29
 * @LastEditTime: 2021-04-28 18:00:35
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \stoms-mp\src\views\Order.vue
-->
<template>
  <div class="box">
    <van-nav-bar
      title="我的订单"
      fixed
      placeholder
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="jumpHome" />
      </template>
    </van-nav-bar>
    <van-list
      v-model="cardLoading"
      :finished="cardFinished"
      :finished-text="cardList.length === 0 ? '暂无订单数据...' : '没有更多了'"
      @load="onCardLoad"
    >
      <div v-for="item in cardList" :key="item.id" class="cardItem" @click="handleCard(item.id)">
        <div class="card" v-for="node in item.details" :key="node.id">
          <img :src="node.good_image_url" class="cardImg">
        </div>
        
        <div class="tips">
          <van-row>
            <van-col span="6">订单编号</van-col>
            <van-col span="18"><span style="color: #7f7f7f; font-size: 14px;">{{ item.number }}</span></van-col>
          </van-row>
          <van-row>
            <van-col span="6">下单时间</van-col>
            <van-col span="18"><span style="color: #7f7f7f; font-size: 14px;">{{ item.create_time }}</span></van-col>
          </van-row>
          <van-row>
            <van-col span="6">订单金额</van-col>
            <van-col span="6"><span style="color: #D9001B">￥{{ item.price }}</span></van-col>
            <van-col span="6">订单状态</van-col>
            <van-col span="6"><span style="color: #D9001B">{{ statusMap[item.status] }}</span></van-col>
          </van-row>
        </div>           
      </div>
    </van-list>
    <!-- <van-tabs v-model="activeName">
      <van-tab title="演出订单" name="performe">
        <van-list
          v-model="performLoading"
          :finished="performFinished"
          :finished-text="performList.length === 0 ? '没有演出订单数据...' : '没有更多了'"
          @load="onLoad"
        >
          <div v-for="item in performList" :key="item" class="performItem">
            <div class="left">
              <img src="../assets/1.jpg" >
              <div class="status">
                <span>待支付</span>
              </div>
            </div>
            <div class="right">
              <div class="title">这里是剧目名称</div>
              <div class="time">2021.04.01 周日 19:00</div>
              <div class="address">西安中贝元剧场</div>
              <div class="price">￥<span>240.00</span> 共3张</div>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="会员卡订单" name="card">
        <van-list
          v-model="cardLoading"
          :finished="cardFinished"
          :finished-text="cardList.length === 0 ? '没有演出订单数据...' : '没有更多了'"
          @load="onCardLoad"
        >
          <div v-for="item in cardList" :key="item" class="cardItem" @click="handleCard(item)">
            <div class="card">
              <div class="top">
                <div class="left">品牌名</div>
                <div class="right">品牌联名</div>
              </div>
              <div class="title">优享季卡</div>
              <div class="des">畅享亲子100天</div>
            </div>
            <div class="tips">
              <div class="status">待支付</div>
              <div class="price">￥588.00</div>
            </div>           
          </div>
        </van-list>
      </van-tab>
    </van-tabs> -->
  </div>
</template>

<script>
import Vue from 'vue';
import { getCardOrderList } from '@/api/order'
import { NavBar, Tab, Tabs, List, Icon, Row, Col } from 'vant';
Vue.use(NavBar).use(Tab).use(Tabs).use(List).use(Icon).use(Row).use(Col);
export default {
  name: 'Order',
  data() {
    return {
      statusMap: {
        0: '待支付',
        1: '已支付',
        '-1': '已取消',
        '-2': '已取消'
      },
      activeName: 'performe',
      performList: [],
      performLoading: false,
      performFinished: false,
      cardList: [],
      cardLoading: false,
      cardFinished: false,
      cardParams: {
        pageSize: 10,
        pageNumber: 1
      }
    };
  },
  mounted() {
    this.cardLoading = true
    this.resetCardParams()
    getCardOrderList(this.cardParams).then(res => {
      if (res.code !== 0) return
      if (res.data.current >= res.data.pages) {
        this.cardFinished = true
      } else {
        this.cardParams.pageNumber++
      }
      this.cardList = res.data.records
      this.cardLoading = false
    })
  },
  methods: {
    jumpHome() {
      this.$router.replace('/')
    },
    resetCardParams() {
      this.cardParams = {
        pageSize:10,
        pageNumber: 1
      }
    },
    handleCard(id) {
      this.$router.push(`/card/order/detail/${id}`);
    },
    onLoad() {
      // 异步更新数据
      // if (!this.cardLoading) {
      //   this.cardLoading = true
      //   getCardOrderList(this.cardParams).then(res => {
      //     if (res.data.current === res.data.pages) {
      //       this.cardFinished = true
      //       if (res.data.records.length !== 0) {
      //         this.cardList.push(...res.data.records)
      //       }
      //     } else {
      //       this.cardParams.pageNumber++
      //       this.cardList.push(...res.data.records)
      //     }
      //     this.cardLoading = false
      //   })
      // }
    },
    onCardLoad() {
      if (!this.cardFinished) {
        this.cardLoading = true
        getCardOrderList(this.cardParams).then(res => {
          if (res.code !== 0) return
          if (res.data.current === res.data.pages) {
            this.cardFinished = true
            if (res.data.records.length !== 0) {
              this.cardList.push(...res.data.records)
            }
          } else {
            this.cardParams.pageNumber++
            this.cardList.push(...res.data.records)
          }
          this.cardLoading = false
        })
      }
    },
  },
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100%;
  background-color: #f4f4f4;
  .performItem {
    width: calc(~"100% - 20px");
    margin: auto;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    .left {
      width: 30%;
      img {
        width: 100%;
      }
      .status {
        font-size: 14px;
        color: #333;
        text-align: center;
        line-height: 2;
      }
    }
    .right {
      width: 65%;
      position: relative;
      .title {
        font-size: 16px;
        color: #333;
        line-height: 2;
      }
      .time {
        font-size: 14px;
        color: #555;
        line-height: 1.5;
      }
      .address {
        font-size: 14px;
        color: #555;
        line-height: 1.5;
      }
      .price {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 12px;
        color: #555;
        line-height: 1.75;
        span {
          font-size: 16px;
        }
      }
    }
  }
  .cardItem {
    width: calc(~"100% - 20px");
    margin: auto;
    background-color: #fff;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 20px;
    .card {
      box-sizing: border-box;
      width: 335px;
      border-radius: 20px;
      overflow: hidden;
      .cardImg {
        display: block;
        width: 100%;
        height: 150px;
      }
    }
    .tips {
      background-color: #fff;
      padding: 10px;
      font-size: 14px;
      color: #555;
      line-height: 2;
      margin-bottom: 10px;
    }
  }
}
</style>
